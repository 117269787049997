<template>
    <div class="add">
        <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
        <div class="add-content">
          <div style="margin-bottom: 40px" v-if="$route.query.type">
            <el-page-header :icon="ArrowLeft" @click="goBack">
              <template #content>
                <span class="text-large font-600 mr-3"> 活动详情 </span>
              </template>
            </el-page-header>
          </div>
            <div class="add-form">
                <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="130px"
                    class="demo-ruleForm"
                    :size="formSize"
                    label-suffix="："
                >
                    <el-form-item class="form-input" label="是否开启活动" prop="enabled">
                        <el-switch v-model="ruleForm.enabled" />
                        <span style="margin-left: 10px;">邀请好友成功后，邀请人和被邀请人都可以得到平台奖励</span>
                    </el-form-item>
                    <div class="title_style">基本信息</div>
                    <el-form-item class="form-input" label="活动标题" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item class="form-input" label="活动时间" prop="date">
                        <el-date-picker @change="handleDatetimerangeChange"
                        v-model="ruleForm.date" type="datetimerange" range-separator="到" start-placeholder="开始时间"
                        end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" />
                    </el-form-item>
            
                    <el-form-item label="活动图片" prop="picture">
                        <upload-img
                            :isAdd="$route.query.id?false:true"
                            :limit="1"
                            @handleAddImgUploadRemove="handleAddImgUploadRemove"
                            ref="imgUploadRef"
                            @handleUploadEmpty="handleUploadRemove"
                            @handleUploadSuccess="handleImgUploadSuccess"
                            :file-list="imgUploadFileList"
                        >
                        </upload-img>
                        <div class="tips">建议尺寸：375*812像素，最多上传1张</div>
                    </el-form-item>
                    <el-form-item label-width="160px" label="邀请成功条件" prop="successCriteria">
                        <el-radio v-model="ruleForm.successCriteria" disabled :label="3" size="large">分享链接给好友</el-radio>
                        <el-radio v-model="ruleForm.successCriteria" disabled :label="2" size="large">好友通过邀请链接注册</el-radio>
                        <el-radio v-model="ruleForm.successCriteria" :label="1" size="large">好友首次到店消费</el-radio>
                    </el-form-item>
                    <el-form-item class="form-input" label="活动规则" prop="activityRules">
                        <el-input style="width: 30%;" v-model="ruleForm.activityRules" :autosize="{ minRows: 3, maxRows: 4 }" maxlength="200" show-word-limit type="textarea"></el-input>
                    </el-form-item>
                    <div class="title_style">奖励机制</div>
                    <NewCustomerTable @changeTabel="changeTabel" :dataList="newcomerRewardList"></NewCustomerTable>
                    <GeneralizationTable @changeTabel="changeTabel" :dataList="promotionRewardList"></GeneralizationTable>
                    <div class="mobile-preview" v-if="!$route.query.type">
                      <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
  </template>
  
  <script lang="js">
  import { breadcrumbList } from './config'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { defineComponent, reactive, ref, watch, computed ,nextTick} from 'vue'
  import { useStore } from 'vuex'
  import pageUpload from '@/components/page-upload/page-upload.vue'
  import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
  import NewCustomerTable from './cpns/newCustomerTable.vue'
  import GeneralizationTable from './cpns/generalizationTable.vue'
  import {rules} from './cpns/config/index'
  import { useRoute } from 'vue-router'
  import { ElMessage } from 'element-plus'
  import { UPLOAD_BASE_URL } from '@/common/constant.js'
  import {createInvitingActivity,editInvitingActivity,getInvitingActivity} from '@/service/main/marketing/index'
  import router from '@/router'
  export default defineComponent({
    props: {
      serveId: {
        type: String,
        default: ''
      },
    },
    components: {
      pageUpload,
      UploadImg,
      Breadcrumb,
      NewCustomerTable,
      GeneralizationTable
    },
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const ruleFormRef = ref()
      const ruleForm = reactive({
        enabled: true,
        title: "",
        picture:"",
        date: null,
        successCriteria:1,
        activityRules:null,
      })
      const newcomerRewardList = ref([])
      const promotionRewardList = ref([])

      const handleImgUploadSuccess = (res) => {
        ruleForm.picture = res.response.data.fileName
        imgUploadFileList.value = [{url:res.response.data.fileName}]
      }
      const handleUploadRemove = () => {
        ruleForm.picture = ''
        imgUploadFileList.value = []
      }

      const handleDatetimerangeChange = value => {
        if (value) {
          ruleForm.startTime = value[0]
          ruleForm.endTime = value[1]
        }else{
          ruleForm.startTime = null
          ruleForm.endTime = null
        }
      }

      const changeTabel = (val,type) =>{
        if (type==1) {
          newcomerRewardList.value=newcomerRewardList.value.concat(val)
        }else{
          promotionRewardList.value=promotionRewardList.value.concat(val)
        }
      }
      const handleSaveClick = async (formEl = undefined) => {
        if (!formEl) return
          await formEl.validate(async (valid, fields) => {
            let flag = Boolean(newcomerRewardList.value.length&&promotionRewardList.value.length)
            if (!flag) {
                ElMessage({
                  message: '新人奖励和推广奖励至少都要添加一条!',
                  type: 'error',
                })
                return
            }
            let flag1 = newcomerRewardList.value.every(item=>{ return item.count })
            let flag2 = promotionRewardList.value.every(item=>{ return item.count })
            if (!(flag1&&flag2)) {
                ElMessage({
                  message: '请完善优惠券的发放数量!',
                  type: 'error',
                })
                return
            }
            if (valid) {
              let res;
              if (route.query.id) {
                res = await editInvitingActivity({ ...ruleForm,id:route.query.id,newcomerRewardList:newcomerRewardList.value,promotionRewardList:promotionRewardList.value })
              }else{
                res = await createInvitingActivity({ ...ruleForm,newcomerRewardList:newcomerRewardList.value,promotionRewardList:promotionRewardList.value })
              }
              if (res.code == 0) {
                ElMessage({
                  message: route.query.id?'编辑成功':'创建成功!',
                  type: 'success',
                })
                router.push({
                  path: '/main/invite/list'
                })
              }else{
                ElMessage({
                  message: res.msg,
                  type: 'error',
                })
              }
              // console.log('submit!')
            } else {
              // console.log('error submit!', fields)
            }
        })
      }
      const goBack = () =>{
        router.go(-1)
      }
      const imgUploadFileList = ref([])
      const initPage = async() =>{
        if (route.query.id) {
          if (route.query.type) {
            breadcrumbList[2].name = '活动详情'
          }else{
            breadcrumbList[2].name = '编辑活动'
          }
          let res = await getInvitingActivity(route.query.id)
          let data = res.data
          ruleForm.enabled = data.enabled;
          ruleForm.title = data.title;
          ruleForm.picture = data.picture;
          imgUploadFileList.value = [{url:data.picture}]
          ruleForm.date = [data.startTime,data.endTime];
          ruleForm.successCriteria = data.successCriteria;
          ruleForm.activityRules = data.activityRules;
          newcomerRewardList.value = data.newcomerRewardList;
          promotionRewardList.value = data.promotionRewardList;
        }
      }
      initPage()
  
      return {
        breadcrumbList,
        ruleForm,
        changeTabel,
        newcomerRewardList,
        promotionRewardList,
        handleImgUploadSuccess,
        handleDatetimerangeChange,
        rules,
        handleSaveClick,
        ruleFormRef,
        handleUploadRemove,
        imgUploadFileList,
        goBack
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
.add {
    min-width: 980px;

    .add-content {
        padding: 20px;
        margin: 20px;
        background-color: #fff;
    }
}
.title_style {
  position: relative;
  padding-left: 12px;
  margin: 20px 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -3px;
    width: 6px;
    height: 22px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(42, 130, 228, 1);
    font-size: 14px;
  }
}
  .add-form {
    .online-reserve {
      &:deep(.el-input) {
        max-width: 120px;
        margin-left: 10px;
      }
    }
    &:deep(.el-input-number .el-input__inner) {
      text-align: left !important;
    }
  
    .tips {
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
    }
  
    .next-btn {
      margin-top: 40px;
      width: 156px;
      height: 48px;
    }
  
    .form-input {
      .other {
        display: inline-block;
        margin-left: 16px;
        color: rgba(42, 130, 228, 1);
        font-size: 12px;
        cursor: pointer;
      }
  
      &:deep(.el-input, .el-select) {
        width: 30%;
        max-width: 400px;
        min-width: 260px;
      }
  
      &:deep(.el-select) {
        width: 30%;
        max-width: 400px;
        min-width: 160px;
  
        .el-input {
          width: 100%;
        }
      }
    }
  
    .format {
      max-width: 1000px;
      min-width: 400px;
  
      .titles {
        display: flex;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        padding: 0 10px;
  
        .huaxian-price {
          flex: 1.3;
        }
  
        div {
          flex: 1;
        }
      }
  
      .format-form {
        .example {
          color: rgba(42, 130, 228, 1);
          font-size: 12px;
  
          .save-btn {
            width: 44px;
            height: 30px;
            line-height: 30px;
            color: rgba(255, 255, 255, 1);
            background-color: rgba(42, 130, 228, 1);
            border-radius: 5px;
            text-align: center;
          }
        }
  
        .top-form,
        .bottom-form {
          display: flex;
  
          div {
            flex: 1;
          }
  
          &:deep(.el-input) {
            margin-right: 20px;
          }
  
          .example {
            display: flex;
          }
        }
  
        .bottom-form {
          &:deep(.el-select) {
            margin-right: 20px;
          }
  
          .other {
            display: flex;
  
            &:deep(.el-input) {
              flex: 1;
            }
  
            .example {
              max-width: 40px;
            }
          }
        }
  
        .top-form {
          margin: 16px 0;
  
          .other {
            display: flex;
  
            &:deep(.el-input) {
              flex: 1;
            }
  
            .example {
              max-width: 40px;
            }
          }
        }
      }
    }
  }
  .mobile-preview {
    padding: 36px 6px 6px 6px;
    min-width: 980px;
    display: flex;
    justify-content: center;
    &:deep(.el-button) {
      width: 156px;
      height: 48px;
    }
  }
  </style>
  