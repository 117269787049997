<template>
  <div style="width: 90%;margin: 0 auto 50px;">
    <el-form
      label-width="120px"
      class="demo-ruleForm"
      label-suffix="："
    >
      <el-form-item class="form-input" label="推广奖励" prop="serveName">
          <el-button type="text" @click="openDialog" v-if="!$route.query.type"><el-icon><Plus /></el-icon>添加优惠券</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="dataTableRef" :data="tableList" highlight-current-row>
      <el-table-column label="名称" prop="name" align="center" />
      <el-table-column label="门槛/面值/类型" prop="contactTheCompany" align="center" width="300">
        <template #default="scope">
          <template v-if="!scope.row.hasThreshold"> 无门槛/ </template>
          <template v-else>订单满 {{ scope.row.thresholdPrice }}元/</template>
          {{ scope.row.type==2?scope.row.discount+'折':'¥'+scope.row.discount}}/
          <template v-if="scope.row.type==1">现金券 </template>
          <template v-if="scope.row.type==2">折扣券 </template>
          <template v-if="scope.row.type==3">兑换券 </template>
        </template>
      </el-table-column>
      <el-table-column label="有效期" prop="validityType" align="center">
        <template #default="scope">
          <template v-if="scope.row.validityType == 1">
            {{ scope.row.startDate + "至" + scope.row.endDate }}
          </template>
          <template v-else>
            <template v-if="scope.row.validityType == 2">
              领取当天后{{ scope.row.days }}天有效
            </template>
            <template v-else> 领取次日后{{ scope.row.days }}天有效 </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="余量" prop="backtoneEvaluation" align="center">
        <template #default="scope">
          <span>{{ scope.row.surplus }}</span>
          <p v-if="scope.row.surplus==0" style="color: red;">（剩余量不足）</p>
        </template>
      </el-table-column>
      <el-table-column label="发放张数" prop="backtoneEvaluation" align="center">
        <template #default="scope">
          <el-input-number :controls="false" v-model="scope.row.count" :max="scope.row.surplus" :min="1" style="width: 100px;" :precision="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150px" v-if="!$route.query.type">
        <template #default="scope">
          <el-button size="small" type="danger" link @click="remove(scope.$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialogVisible" title="选择优惠券" width="1200px" :before-close="closeDialog"
      :close-on-click-modal="false" top="10vh">
      <!-- <el-scrollbar height="350px"> -->
          <el-tabs v-model="activeStatus" type="card" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in tabStatusList" :key="index" :label="item.label" :name="item.value">
            </el-tab-pane>
            <div class="coupons-list">
              <el-table ref="multipleTableRef" :data="dataList" highlight-current-row @selection-change="handleSelectionChange" height="400">
                <el-table-column type="selection" :align="align" width="60" :selectable="handleSelectionDisable"/>
                <el-table-column label="名称" prop="name" align="center" />
                <el-table-column label="门槛/面值/类型" prop="contactTheCompany" align="center" width="300">
                  <template #default="scope">
                    <template v-if="!scope.row.hasThreshold"> 无门槛/ </template>
                    <template v-else>订单满 {{ scope.row.thresholdPrice }}元/</template>
                    {{ scope.row.type==2?scope.row.discount+'折':'¥'+scope.row.discount}}/
                    <template v-if="scope.row.type==1">现金券 </template>
                    <template v-if="scope.row.type==2">折扣券 </template>
                    <template v-if="scope.row.type==3">兑换券 </template>
                  </template>
                </el-table-column>
                <el-table-column label="有效期" prop="validityType" align="center">
                  <template #default="scope">
                    <template v-if="scope.row.validityType == 1">
                      {{ scope.row.startDate + "至" + scope.row.endDate }}
                    </template>
                    <template v-else>
                      <template v-if="scope.row.validityType == 2">
                        领取当天后{{ scope.row.days }}天有效
                      </template>
                      <template v-else> 领取次日后{{ scope.row.days }}天有效 </template>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="余量" prop="backtoneEvaluation" align="center">
                  <template #default="scope">
                    <span>{{ scope.row.surplus }}</span>
                    <p v-if="scope.row.surplus==0" style="color: red;">（剩余量不足）</p>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
                :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
          </el-tabs>
        <!-- </el-scrollbar> -->
      <div class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { getCashCouponsList } from '@/service/main/goods'
import PageContent from '@/components/page-content2/src/page-content.vue'
export default defineComponent({
  props: {
    dataList: {
      type: Array,
      default: [],
    }
  },
  components: {
    PageContent
  },
  emits: ['changeTabel','refresh'],
  setup(props, { emit }) {
    const dialogVisible = ref(false)
    const tableList = ref([])
    const type = ref(null)
    const multipleTableRef = ref()
    const formData = ref({
      date: null,
      contactTheCompany: null,
      linkman: null,
      backtoneEvaluation: null
    })
    const tabStatusList = reactive([
    // {
    //   value: 0,
    //   label: '全部'
    // },
    {
      value: 1,
      label: '现金券'
    },
    // {
    //   value: 2,
    //   label: '折扣券'
    // },
    {
      value: 3,
      label: '兑换券'
    }])
    const dataList = ref([])
    const dataCount = ref(0)
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const handleCurrentChange = page => {
      queryInfo.page = page
      getCouponList()
    }
    const handleSizeChange = (pageSize) => {
      queryInfo.pagesize = pageSize
      getCouponList()
    }
    const rules = reactive({
      date: [{ required: true, message: "请选择", trigger: "change" }],
      contactTheCompany: [{ required: true, message: "请输入", trigger: "blur" }],
      linkman: [{ required: true, message: "请输入", trigger: "blur" }],
      backtoneEvaluation: [{ required: true, message: "请输入", trigger: "blur" }]
    });
    watch(() => props.dataList, (newVal) => {
      if (newVal) {
        console.log(newVal);
        tableList.value = newVal
      }
    },
      {
        deep: true,
        immediate: true,
      })
    const openDialog = (row, index) => {
      if (row) {
        formData.value = Object.assign({}, row)
        formData.value.index = index
        type.value = 1
      } else {
        type.value = null
      }
      dialogVisible.value = true
    }
    const closeDialog = () => {
      multipleTableRef.value.clearSelection()
      dialogVisible.value = false
    }
    const handleSelectionDisable = (row, i)=> { // 设置可选中的条件
      if (row.surplus == 0) return false
      if(!tableList.value.length) return true
      return !tableList.value.some(item => item.id === row.id)
    }
    const handleSubmit = () => {
      if (!(ids.value&&ids.value.length)) {
        ElMessage({
          message: '请先选择优惠券!',
          type: 'error',
        })
        return
      }
      emit('changeTabel', ids.value,2)
      closeDialog()
    }
    const remove = (index) => {
      tableList.value.splice(index, 1)
    }
    const handleClick = async () => {
      queryInfo.page = 1
      getCouponList()
    }
    const ids = ref([])
    const handleSelectionChange = (selection) =>{
      ids.value = selection
      // ids.value = selection.map((item: any) => item.id);
    }

    const activeStatus = ref(1)
    const getCouponList = async () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        type:activeStatus.value==0?undefined:activeStatus.value
      }
      const res = await getCashCouponsList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    getCouponList()
    return {
      dialogVisible,
      tableList,
      remove,
      handleSubmit,
      closeDialog,
      openDialog,
      rules,
      formData,
      handleClick,
      getCouponList,
      handleCurrentChange,
      dataList,
      dataCount,
      queryInfo,
      tabStatusList,
      activeStatus,
      handleSelectionChange,
      multipleTableRef,
      handleSelectionDisable,
      handleSizeChange
    }
  }
})

</script>

<style scoped lang="less">
&:deep(.el-table) {


.el-table__header-wrapper {
  
  .el-table__cell {

    background-color: rgba(239, 239, 239, 1);

    .cell {
      color: rgba(80, 80, 80, 1) !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

}
}
.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}
</style>